var exports = {};

/*! Javascript-number-formatter v2.0.9 */
!function (e, t) {
  exports = t();
}(exports, function () {
  "use strict";

  const e = /[0-9\-+#]/,
        t = /[^\d\-+#]/g;

  function n(t) {
    return t.search(e);
  }

  return (e, i, r = {}) => {
    if (!e || isNaN(Number(i))) return i;

    const s = function (e = "#.##") {
      const i = {},
            r = e.length,
            s = n(e);
      i.prefix = s > 0 ? e.substring(0, s) : "";
      const a = n(e.split("").reverse().join("")),
            o = r - a,
            u = e.substring(o, o + 1),
            l = o + ("." === u || "," === u ? 1 : 0);
      i.suffix = a > 0 ? e.substring(l, r) : "", i.mask = e.substring(s, l), i.maskHasNegativeSign = "-" === i.mask.charAt(0), i.maskHasPositiveSign = "+" === i.mask.charAt(0);
      let g = i.mask.match(t);
      return i.decimal = g && g[g.length - 1] || ".", i.separator = g && g[1] && g[0] || ",", g = i.mask.split(i.decimal), i.integer = g[0], i.fraction = g[1], i;
    }(e),
          a = function (e, t, n) {
      let i = !1;
      const r = {
        value: e
      };
      e < 0 && (i = !0, r.value = -r.value), r.sign = i ? "-" : "", r.value = Number(r.value).toFixed(t.fraction && t.fraction.length), r.value = Number(r.value).toString();
      const s = t.fraction && t.fraction.lastIndexOf("0");
      let [a = "0", o = ""] = r.value.split(".");
      return (!o || o && o.length <= s) && (o = s < 0 ? "" : Number("0." + o).toFixed(s + 1).replace("0.", "")), r.integer = a, r.fraction = o, function (e, t) {
        e.result = "";
        const n = t.integer.split(t.separator),
              i = n.join(""),
              r = i && i.indexOf("0");
        if (r > -1) for (; e.integer.length < i.length - r;) e.integer = "0" + e.integer;else 0 === Number(e.integer) && (e.integer = "");
        const s = n[1] && n[n.length - 1].length;

        if (s) {
          const n = e.integer.length,
                i = n % s;

          for (let r = 0; r < n; r++) e.result += e.integer.charAt(r), !((r - i + 1) % s) && r < n - s && (e.result += t.separator);
        } else e.result = e.integer;

        e.result += t.fraction && e.fraction ? t.decimal + e.fraction : "";
      }(r, t), "0" !== r.result && "" !== r.result || (i = !1, r.sign = ""), !i && t.maskHasPositiveSign ? r.sign = "+" : i && t.maskHasPositiveSign ? r.sign = "-" : i && (r.sign = n && n.enforceMaskSign && !t.maskHasNegativeSign ? "" : "-"), r;
    }(i, s, r);

    return s.prefix + a.sign + a.result + s.suffix;
  };
});
export default exports;